import { CopyAllOutlined } from "@mui/icons-material";
import { tableInstanceSignal } from "../CustomTable";
import { CopyCells } from "./CopyCells";

interface CopyToFilterProps {
  value: string;
  row?: any;
  column?: any;
  isCopyText?: boolean
}

const CopyToFilter: React.FC<CopyToFilterProps> = ({ value, row, column, isCopyText }) => {
  const copyToFilter = () => {
    const input = document.getElementById("globalFilter") as HTMLInputElement;
    if (input) {
      input.value = value;
      input.focus();
      tableInstanceSignal.value.setGlobalFilter(value);
    }
  };
  return (
    <div className="copyCellWrapper">
      <CopyAllOutlined onClick={copyToFilter} />
      <span>{isCopyText ? <CopyCells row={row} value={value} column={column}/> : value}</span>
    </div>
  );
};

export default CopyToFilter;
