import { atom } from 'jotai'
import { AdminTableSelection } from '../features/adminFeatures/tables/tableTypes';
import { signal } from '@preact/signals-react';
export const initPreLoginUser = {
  email: '',
  password: '',
  accountType: '',
  referral: '',
  promo: '',
  utm: '',
  redirect: ''
}
export const preLoginUser = atom(initPreLoginUser)

type FilterToggleType = {
  [key: string]: {
    isChecked: boolean;
    header: string;
    isTrueChecked?: boolean;
    isFalseChecked?: boolean;
  };
};

export const initAdminAppState = {
  selectedTable: AdminTableSelection.MOVIE_ASSET,
  filterToggles: {
    released: false,
    searchable: false,
    noPoster: false,
    noIMDB: false,
    classification: false,
    unreleased: false,
  },
  adminDrawer: { open: false, values: undefined, rowIndex: undefined },
  filterToggle: {
    released: { isChecked: false, header: '' },
    searchable: { isChecked: false, header: '' },
    noPoster: { isChecked: false, header: '' },
    noIMDB: { isChecked: false, header: '' },
    classification: { isChecked: false, header: '' },
    unreleased: { isChecked: false, header: '' },
  } as FilterToggleType,
  searchValue: 'Search',
  loading: false,
};

// Create a signal for initAdminAppState
export const adminAppStateSignal = signal(initAdminAppState);