import { useEffect, useRef, useState } from 'react';
import {
  Account,
  AdminIndicator,
  DropdownItem,
  DropdownMenu,
  DvdSignin,
  HomeIcon,
  Login,
  Logo,
  LogoButton,
  NavBar,
  NavIcon,
  NavTool,
  PreNav,
  SignupButton,
} from './Nav.styles';
import ClearPlayLogo from '../../assets/images/clearplay.svg';
import AccountImg from '../../assets/images/account-cropped.svg';
import { AppRoutes } from '../../app/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { DeviceType } from '../user/userTypes';
import Home from '../../assets/images/home.svg';
import MobileHome from '../../assets/images/home-mobile.png';

import ArrowRight from '../../assets/images/arrow-right-gray.svg';
import { useTagManager } from '../../hooks/useTagManager';
import { userDeviceSiganl, userIsAmdinSginal, userIsSupportSignal, userLoadingSignal, userLoggedInSignal, userUUIDSignal } from '../user/userSignals';
import { signal } from '@preact/signals-react';
import { useCookies } from 'react-cookie';
import { RemoveLocalStorageItems } from '../../utils/localStorageService';
import { useSetAtom } from 'jotai';
import { initPreLoginUser, preLoginUser } from '../../app/store';
import { queryClient } from '../../lib/react-query';

const LoginSignupPages = [
  AppRoutes.ENTER_EMAIL,
  AppRoutes.ENTER_PASSWORD,
  AppRoutes.SIGNIN_CALLBACK,
  AppRoutes.SIGNUP_EMAIL,
  AppRoutes.SIGNUP_PASSWORD,
  AppRoutes.SIGNUP_PAYMENT,
  AppRoutes.ACCOUNT_FOUND,
  AppRoutes.COLLECT_EMAIL,
];

const HomeLinkPages = [
  AppRoutes.MEMBERSHIP_AND_PAYMENT,
  AppRoutes.MY_ACCOUNT,
  AppRoutes.SIGNIN_CALLBACK,
];

const isScrolled = signal(false)

const Nav: React.FC = () => {
  const history = useHistory();
  const isAdmin = window.location.pathname.includes('admin');
  const [, setUpdate] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [, removeCookie] = useCookies(["clearplay_token"]);
  const setUserData = useSetAtom(preLoginUser);

  const { dataLayerPush} = useTagManager()
  const loading = userLoadingSignal.value
  useEffect(() => {
    setUpdate((update) => !update);
    
  }, [location, history]);

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  const logoClick = () => {
    if (location.pathname === AppRoutes.HOME) {
      window.open('https://join.clearplay.com/about', '_self');
    } else history.push(AppRoutes.HOME);
  };

  const scrollPosition = ()=> {
    const lastKnownScrollPosition = window.scrollY;
    if (lastKnownScrollPosition > 20) {
      isScrolled.value = (true);
    } else isScrolled.value = (false);
  }
  const logout = async () => {
    setUserData(initPreLoginUser);
    RemoveLocalStorageItems();
    removeCookie("clearplay_token", { domain: ".clearplay.com" });
    // deleteAllCookies();
    queryClient.clear();
    setTimeout(() => {
      window.location.pathname = "/";
    }, 500);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false); // Close the dropdown if the click is outside
      }
    };
  
    if (isDropdownVisible) {
      // Add event listener when the dropdown is visible
      document.addEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      // Clean up the event listener when the dropdown is closed or the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible]); // This effect depends on `isDropdownVisible`

  useEffect(() => {
    document.addEventListener('scroll',scrollPosition);
    return () => {
      document.removeEventListener('scroll', scrollPosition)
    }
  },[])

  const loginClick = () => {
    if (
      userDeviceSiganl.value === DeviceType.MOBILE ||
      userDeviceSiganl.value === DeviceType.TABLET
    ) {
      history.push(AppRoutes.ENTER_EMAIL);
    } else {
      // no extension
      // setCookie('extension_post_install_url',
      // `${config.redirectUrl}${AppRoutes.ENTER_EMAIL}`,  { domain: '.clearplay.com' })

      let userAgent = navigator.userAgent;
      // not on chrome?
      if (!userAgent.match(/chrome|chromium|crios/i)) {
        window.open(
          `https://join.clearplay.com/browser?redirect_url=${process.env.REACT_APP_REDIRECT}${AppRoutes.ENTER_EMAIL}`,
          '_self'
        );
      } else {
          history.push(AppRoutes.ENTER_EMAIL);
      }
    }
  };

  const signupClick = () => {
      history.push(AppRoutes.SIGNUP_EMAIL);

    dataLayerPush({
        cpId: userUUIDSignal.value,
        event: 'view_item',
        ecommerce: {
          items: [{
            item_name: '30 Day Trial',
            item_id: '30dT',
            price: '0.00',
            item_brand: 'clearplay',
            item_category: 'subscription',
            quantity: '1'
          }]
        }
    });
  };

  function loggedInNav() {
    return (
      <NavBar position='fixed' transparent={isScrolled.value || window.location.pathname === AppRoutes.SEARCH ? 1 : 0}>

        <NavTool>
          <LogoButton
            onClick={() => (!loading ? logoClick() : null)}
            edge='start'
          >
            <Logo src={ClearPlayLogo} alt='Clearplay' />
            {isAdmin && <AdminIndicator>ADMIN</AdminIndicator>}
          </LogoButton>
          <Account>
            {HomeLinkPages.includes(window.location.pathname as AppRoutes) && (
              <HomeIcon
                onClick={() => history.push(AppRoutes.HOME)}
                src={Home}
              />
            )}
            {/* {
              ((window.location.pathname === AppRoutes.HOME  || window.location.pathname === AppRoutes.ADMIN_HOME) && (
                <NavIcon className='search-icon' src=
                {Search}  onClick={() =>
                  !loading ? history.push(AppRoutes.SEARCH) : null
                }/>

              ))
            } */}
            <NavIcon
              src={AccountImg}
              onMouseEnter={() => setIsDropdownVisible(true)}
              onMouseLeave={() => setIsDropdownVisible(false)}
            />
            {isDropdownVisible && (
              <DropdownMenu
              onMouseEnter={() => setIsDropdownVisible(true)} // Keep the menu open when hovering over it
              onMouseLeave={() => setIsDropdownVisible(false)} // Close the menu when leaving it
            >
                {(userIsAmdinSginal.value || userIsSupportSignal.value) && <DropdownItem onClick={() =>  {
                  history.push(AppRoutes.ADMIN_HOME)}}>Admin Portal</DropdownItem>}
                <DropdownItem onClick={
                  () => {
                    history.push(AppRoutes.MY_ACCOUNT)}}>My Account</DropdownItem>
                <DropdownItem onClick={() => {
                  window.open("https://clearplay.document360.io", '_blank')}}>Help</DropdownItem>
                <DropdownItem onClick={() => {
                  logout()}}>Logout</DropdownItem>
              </DropdownMenu>
            )}
          </Account>
        </NavTool>
      </NavBar>
    );
  }

  function preLoggedInNav() {
    return (
      <PreNav position='fixed' transparent={isScrolled.value ? 0 : 1}>
        <NavTool>

          <LogoButton
            edge='start'
            onClick={() => (!loading ? logoClick() : null)}
          >
            <Logo src={ClearPlayLogo} alt='Clearplay' />
          </LogoButton>

          {!LoginSignupPages.includes(
            window.location.pathname as AppRoutes
          ) && (

            <Account>
               {HomeLinkPages.includes(window.location.pathname as AppRoutes) && (
              <HomeIcon className={userDeviceSiganl.value === 'mobile' ? 'pre-login  mobile' : ' pre-login'}
                onClick={() => history.push(AppRoutes.HOME)}
                src={userDeviceSiganl.value === 'mobile' ? MobileHome : Home}
              />
            )}
              <SignupButton onClick={() => signupClick()}>
                Start Clearplay 30-Day Free Trial
              </SignupButton>
              <Login onClick={() => loginClick()}>Log in</Login>
            </Account>
          )}
          {window.location.pathname === AppRoutes.ENTER_EMAIL && (
            <Account>
              <DvdSignin
                onClick={() =>
                  window.open(
                    'https://join.clearplay.com/disc-sign-in/',
                    '_self'
                  )
                }
              >
                <span>DVD Player log in</span>
                <img src={ArrowRight} alt='' />
              </DvdSignin>
            </Account>
          )}
        </NavTool>
      </PreNav>
    );
  }
  if (location.pathname === AppRoutes.JOIN_CLEARPLAY) return null
  return userLoggedInSignal.value ? loggedInNav() : preLoggedInNav();
};

export default Nav;
