import { TextField } from '@mui/material';

interface VersionInputProps {
  value: string;
  setValue: Function;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const VersionInput: React.FC<VersionInputProps> = ({
  value,
  setValue,
  onBlur,
}) => {
  return (
    <TextField
      style={{ maxWidth: '50px' }}
      variant="standard"
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

export { VersionInput };
