interface IPlatformYesNo {
  row: any;
  value: string;
  column: any;
}

export const PlatformYesNo: React.FC<IPlatformYesNo> = ({
  row,
  value,
  column,
}) => {

  if (row.sGrouped) return <span />;
  return (
    <span id="yesNoCell">
      {value ? (
        <span style={{color: '#00875a'}}>Yes</span >
      ) : column.id === 'img_URL' ? (
        <span className='pulsingText'>No</span>
      ) : (
        <span style={{color: '#ff7452'}}>No</span>
      )}
    </span>
  );
};
