interface IOnOffCell {
  value: boolean;
  column: any;
}

export const OnOffCell: React.FC<IOnOffCell> = ({
  value,
}) => {
  return (
    <span>
      {value === true ? (
       <span style={{color: '#00875a'}}>On</span>
      ) : (
        <span style={{color: '#ff7452'}}>Off</span>
      )}
    </span>
  );
};
