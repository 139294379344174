import {
  useIsFetching,
  useQuery,
} from "@tanstack/react-query";
import { ExtractFnReturnType, Queries } from "../../../lib/react-query";
import { useEffect, useState } from "react";
import {
  fetchClassifications,
  fetchPlatformConfigs,
  fetchShelfList,
  fetchTableData,
} from "./table.api";
import {
  AdminTableSelection,
  FetchTableDataUtilQueryKeysEnum,

} from "./tableTypes";
import { TableReturnResults } from "../admin.types";
import { userMessageSignal } from "../../user/userSignals";

export const useTableData = () => {
  const [tableLoading, setIsTableLoaing] = useState(false);

  const isTableLoading = useIsFetching([Queries.FETCH_TABLE_DATA]);
  type FetchTableDataQueryFnType = typeof fetchTableData;



  const useFetchTableDataQuery = (
    assetType: AdminTableSelection
  ) => {
    return useQuery<ExtractFnReturnType<FetchTableDataQueryFnType>>({
      queryKey: [Queries.FETCH_TABLE_DATA],
      queryFn: async () => await fetchTableData({ assetType: assetType }),
      onSuccess: async (data: TableReturnResults) => {
        return data;
      },
      onError: (error: any) => {
        userMessageSignal.value = {
          message: error?.data?.message,
          color: "red",
        }
      },
      // retry: false
      enabled: isTableLoading === 0,
    });
  };

  type FetchTableDataUtilQueryFnType =
    | typeof fetchClassifications
    | typeof fetchShelfList
    | typeof fetchPlatformConfigs

  interface FetchTableDataUtilQueryOptions {
    queryKey: FetchTableDataUtilQueryKeysEnum;
    queryFn: (...args: any[]) => Promise<any>;
    initialParams?: any[]; // Define the initial parameters here
  }

  const useFetchTableUtilDataQuery = ({
    queryKey,
    queryFn,
    initialParams = [],
  }: FetchTableDataUtilQueryOptions) => {
    return useQuery<ExtractFnReturnType<FetchTableDataUtilQueryFnType>>({
      queryKey: [queryKey, ...initialParams], // Pass initial params to the query key for cache
      queryFn: async () => await queryFn(...initialParams), // Spread initial params here
      onSuccess: async (data: any) => {
        return data;
      },
      onError: async (error: any) => {
        console.log("error fetching data", error);
      },
      enabled: false,
    });
  };
  useEffect(() => {
    const newLoadingState = isTableLoading > 0;

    setIsTableLoaing((prev) => {
      if (prev !== newLoadingState) {
        return newLoadingState;
      } else return prev;
    });
  }, [isTableLoading]);

  return { useFetchTableDataQuery, useFetchTableUtilDataQuery, tableLoading };
};
