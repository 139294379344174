import { TextField } from '@mui/material';

interface INumberInputCell {
  value: string | number;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onInputBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const NumberInputCell: React.FC<INumberInputCell> = ({
  value,
  onInputChange,
  onInputBlur,
}) => <TextField style={{maxWidth: '25px'}} className='cellInput' variant="standard" value={value} onChange={onInputChange} onBlur={onInputBlur} />;
