import React from "react";
import { Button } from "@mui/material";
import { patchAssetDetails } from "../../../features/adminFeatures/assets/asset.api";
import { userMessageSignal } from "../../../features/user/userSignals";
import './CellComponents.scss';

interface ActionColumnProps {
  value: string;
  row: {
    index: number;
    original: {
      asset_id: number;
      asset_identifier_id: number;
      filter_id: number;
      video_id: string;
    };
  };
  updateMyData: (
    index: number,
    id: string | null,
    videoId: string | null,
    key: string | null,
    value: any | null,
    action: string
  ) => void;
}

const ActionColumn: React.FC<ActionColumnProps> = ({ value, row, updateMyData }) => {
  const handleUnrelease = async () => {
    try {
      const res = await patchAssetDetails({
        options: {
          asset_id: row.original.asset_id,
          asset_identifier_id: row.original.asset_identifier_id,
          filter_id: row.original.filter_id,
          filter_released: false,
        },
        type: "movies",
      });

      if (res.indexUpdated) {
        updateMyData(row.index, null, row.original.video_id, null, null, "removeRow");
      }
    } catch (error: any) {
      userMessageSignal.value = {
        message: error.message,
        color: "red",
      };
    }
  };

  if (value === "Unrelease") {
    return (
      <Button variant="text" onClick={handleUnrelease}>
        <span style={{ fontSize: "12px" }}>{value}</span>
      </Button>
    );
  }

  return (
    <span id="actionCell">
      {value}
    </span>
  );
};

export default ActionColumn;