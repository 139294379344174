import { useCallback, useEffect } from "react";
import {
  LocalStorage,
  getLocalStorageItem,
  getTokenData,
  removeTokenData,
} from "../utils/localStorageService";
import { useCookies } from "react-cookie";
import { useUser } from "../features/user/useUser";
import { AppRoutes } from "../app/routes";
import {
  userLoadingSignal,
  userLoggedInSignal,
  userMessageSignal,
  userUUIDSignal,
} from "../features/user/userSignals";
import { uniqueId } from "lodash";
import dayjs from "dayjs";
import { deleteAllCookies, useRoleDetector } from "../utils/userUtil";
import { useAtom } from "jotai";
import { preLoginUser } from "../app/store";
import { init } from "@noriginmedia/norigin-spatial-navigation";
import { startExtensionMutationObserver } from "../features/detectors/ExtensionDetector";

init({
  // options
  debug: false,
  visualDebug: false,
});


export const useAppInit = () => {
  const { roleDetector } = useRoleDetector();
  const [uuidCookie, setUuidCookie] = useCookies(["cpId"]);
  const [cookies, setCookie, removeCookie] = useCookies(["clearplay_token", 'cp_isLoggedIn']);
  const { fetchUserFilteringStatus } = useUser();
  const { useMe } = useUser();
  const { data: me, refetch: fetchMe, isFetching } = useMe();
  const [preLogin, setPreLoginCodes] = useAtom(preLoginUser);


  const checkUserLoginStatus = useCallback(async () => {
    if (
      process.env.REACT_APP_ENV === "PROD" ||
      process.env.REACT_APP_ENV === "DEV"
    ) {
      const token = await getTokenData();
      if (!token && window.location.pathname === AppRoutes.HOME) {
        window.location.pathname = AppRoutes.JOIN_CLEARPLAY;
      }
    }
  }, []);

  const setUUID = useCallback(async () => {
    if (uuidCookie.cpId) {
      userUUIDSignal.value = uuidCookie.cpId;
    }
    if (!uuidCookie.cpId) {
      const id = uniqueId("cpId_");
      setUuidCookie("cpId", id, {
        expires: dayjs().add(7, "days").toDate(),
        domain: ".clearplay.com",
      });
    }
  }, [uuidCookie, setUuidCookie]);

  const init = useCallback(async () => {
    await checkUserLoginStatus();
    await setUUID();
    await fetchUserFilteringStatus();
  }, [checkUserLoginStatus, setUUID, fetchUserFilteringStatus]);

  const userData = useCallback(async () => {
    if (!me?.user_id && !isFetching) {
      await fetchMe();
    }
  }, [me, isFetching]);

  const handleUserAuthentication = useCallback(async () => {
    const token = await getTokenData();
    if (token && window.location.pathname !== AppRoutes.SIGNIN_CALLBACK) {
      userLoggedInSignal.value = true;
      userLoadingSignal.value = false;
      try {
        roleDetector(token);
        await userData();

        if (
          window.location.pathname === AppRoutes.SIGNUP_EMAIL ||
          window.location.pathname === AppRoutes.ENTER_EMAIL
        )
          return;
        setCookie("cp_isLoggedIn", 'true', {
          domain: `${process.env.REACT_APP_DOMAIN}`,
        })
        setCookie("clearplay_token", `${token}`, {
          domain: `${process.env.REACT_APP_DOMAIN}`,
          expires: dayjs().add(6, "months").toDate(),
        });
        if (window.location.pathname === AppRoutes.ENTER_EMAIL) {
          window.location.pathname = AppRoutes.MY_ACCOUNT
        }
      } catch (err) {
        removeTokenData();
        deleteAllCookies();
        userMessageSignal.value = {
          message: "You have been logged out, please log in again.",
          color: "red",
        };
        if (window.location.pathname !== AppRoutes.ENTER_EMAIL) {
          window.location.pathname = AppRoutes.ENTER_EMAIL
        }
      }
    }
  }, [ roleDetector, setCookie, userData]);

  useEffect(() => {
    startExtensionMutationObserver()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const redirect = getLocalStorageItem(LocalStorage.REDIRECT_URL);
    const promo = getLocalStorageItem(LocalStorage.PROMO_CODE);
    const referral = getLocalStorageItem(LocalStorage.REFERRAL_SOURCE);

    setPreLoginCodes((prev) => ({
      ...prev,
      referral: referral ? referral : "",
      promo: promo ? promo : "",
      redirect: redirect ? encodeURIComponent(redirect) : "",
    }));
  }, []);

  useEffect(() => {
    init();
  }, [init]);


  useEffect(() => {
    const removeHostOnlySessionCookie = (name: string, path: string = '/') => {
      document.cookie = `${name}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    };

    const badCookieName = 'clearplay_token';
    const badCookieValueStartsWith = '%7B%22domain';
    const badCookiePresent = document.cookie.split('; ').find(cookie => {
      let [name, value] = cookie.split('=');
      return name === badCookieName && value.startsWith(badCookieValueStartsWith);
    });

    if (badCookiePresent) {
      removeHostOnlySessionCookie(badCookieName);
      console.log(`Old cookie '${badCookieName}' removed`);
    }
  }, []);
  
  useEffect(() => {
    handleUserAuthentication();
  }, [handleUserAuthentication]);



};
