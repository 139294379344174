import { displayProviderMap } from "../../../app/app.types";
import {
  AdminAsset,
  CustomerComment,
  Platform,
  ShowsAsset,
} from "./tableTypes";

export const ConstructPlatformArray = (data: any[]): Platform[] => {
  const platforms = data.map((pdata: any): Platform => {
    return {
      platform_id: pdata.platform_id,
      name: pdata.name,
      time_offset: pdata.time_offset,
      requires_membership: pdata.requires_membership,
      enabled: pdata.enabled,
      maintenanceMessage: pdata.maintenanceMessage,
      moviesActive: pdata.moviesActive,
      showsActive: pdata.showsActive,
      isSearchable: pdata.searchable,
      web_banner: pdata.web_banner,
      web_shelves: pdata.web_shelves,
      web_search: pdata.web_search,
      minimumVersion: pdata.minimumVersion,
    };
  });
  return platforms;
};

export const FilteringMessageConstructor = (data: any[]): any[] => {
  const filteringDownInfo = data?.map((item) => {
    const container: any = {};

    container.platform_id = item.platformId;
    container.message = item.message;
    container.web_banner = item.web_banner;
    container.web_search = item.web_search;
    container.web_shelves = item.web_shelves;
    return container;
  });

  return filteringDownInfo;
};

export const ConstuctCustomerCommentArray = (
  data: any[]
): CustomerComment[] => {
  const comments = data.map((cData: any): CustomerComment => {
    let totalVotes;
    const imdbArray = data.filter((d) => {
      return d.imdb_id === cData.imdb_id;
    });
    if (imdbArray) {
      totalVotes = imdbArray.reduce((accum, obj) => {
        return accum + obj.total_votes;
      }, 0);
    } else totalVotes = 1;
    return {
      display_name: cData.display_name,
      filter_release_date: cData.filter_release_date,
      platform_title: cData.platform_title,
      released: cData.released,
      asset_score_id: cData.asset_score_id,
      comment: cData.comment,
      created_at: cData.created_at,
      email: cData.email,
      extension_version: cData.extension_version,
      imdb_id: cData.imdb_id,
      modified_at: cData.modified_at,
      partner_identifier: cData.partner_identifier,
      platform_id: cData.platform_id,
      reported_at: cData.reported_at,
      resolved_at: cData.resolved_at,
      score: cData.score,
      url: cData.url,
      user_id: cData.user_id,
      browser_name: cData.browser_name,
      browser_version: cData.browser_version,
      device_type: cData.device_type,
      os_name: cData.os_name,
      os_version_name: cData.os_version_name,
      os_version: cData.os_version,
      asset_type_id: cData.asset_type_id,
      total_votes: totalVotes,
      unreproducible_at: cData.unreproducible_at,
    };
  });
  return comments;
};

export const ConstructMovieAssetArray = (assets: any[]): AdminAsset[] => {
  const assetArray = assets.map((data) => {
    return {
      asset_id: data.asset_id,
      asset_identifier_id: data.asset_identifier_id,
      imdb_id: data.imdb_id,
      img_URL: data.img_URL ? data.img_URL : data.img_url,
      name: data.name,
      display_name: data.display_name,
      release_date: data.release_date,
      duration: data.duration,
      credit_start_time: data.credit_start_time,
      filter_released: data.filter_released,
      platform_id: displayProviderMap[data.platform_id],
      filter_release_date: data.filter_release_date,
      searchable: data.searchable,
      filter_id: data.filter_id,
      classification_version_id: data.classification_version_id,
      video_id: data.video_id,
      primary_video_id: data.primary_video_id,
      display_duration: data.display_duration,
      video_duration: data.video_duration,
      off_brand: data.off_brand,
    };
  });
  return assetArray;
};

export const ConstructShowAssetArray = (assets: any[]): ShowsAsset[] => {
  const assetArray = assets.map((data) => {
    return {
      episode_asset_id: data.episode_asset_id,
      episode_asset_identifier_id: data.episode_asset_identifier_id,
      episode_credit_start_time: data.episode_credit_start_time,
      episode_display_name: data.episode_display_name,
      episode_duration: data.episode_duration,
      episode_filter_release_date: data.episode_filter_release_date,
      episode_filter_released: data.episode_filter_released,
      episode_imdb_id: data.episode_imdb_id,
      episode_name: data.episode_name,
      episode_number: data.episode_number,
      episode_partner_identifier: data.episode_partner_identifier,
      platform: displayProviderMap[data.episode_platform_id],
      episode_platform_id: data.episode_platform_id,
      season_asset_id: data.season_asset_id,
      season_number: data.season_number,
      series_asset_id: data.series_asset_id,
      series_display_name: data.series_display_name,
      series_imdb_id: data.series_imdb_id,
      series_img_URL: data.series_img_URL,
      series_name: data.series_name,
      series_searchable: data.series_searchable,
      episode_classification_version_id: data.episode_classification_version_id,
      episode_filter_id: data.episode_filter_id,
      series_partner_identifier: data.series_partner_identifier,
      series_platform_title: data.series_platform_title,
      series_video_id: data.series_video_id,
      episode_display_duration: data.episode_display_duration,
      episode_video_duration: data.episode_video_duration,
    };
  });
  return assetArray;
};
export const ConstructDurationMismatchesArray = (entries: any[]): any[] => {
  const missmatchesArray = entries.map((data) => {
    return {
      asset_duration: data.asset_duration,
      browser_name: data.browser_name,
      browser_version: data.browser_version,
      dev_mode: data.dev_mode,
      device_type: data.device_type,
      duration_diff: data.duration_diff,
      email: data.email,
      extension_version: data.extension_version,
      ip_address: data.ip_address,
      os_name: data.os_name,
      os_version_name: data.os_version_name,
      os_version: data.os_version,
      created_at: data.created_at,
      filter_release_date: data.filter_release_date,
      modified_at: data.modified_at,
      platform_id: data.platform_id,
      reported_at: data.reported_at,
      resolved_at: data.resolved_at,
      silent: data.silent,
      url: data.url,
      user_id: data.user_id,
      video_duration: data.video_duration,
      video_id: data.video_id,
      video_name: data.video_name,
      video_time: data.video_time,
      asset_imdb_id: data.asset_imdb_id,
      asset_type_id: data.asset_type_id,
      asset_type_name: data.asset_type_name,
      database_asset_duration: data.database_asset_duration,
      database_filter_release_date: data.database_filter_release_date,
      database_filter_released: data.database_filter_released,
      user: data.email || data.user_id,
      report_duration_mismatch_id: data.report_duration_mismatch_id,
      unreproducible_at: data.unreproducible_at,
    };
  });
  return missmatchesArray;
};
