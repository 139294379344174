import { getFilmUrl, selectedTableSignal } from "../../utils";
import './CellComponents.scss';

interface IGoToFilmCell {
  row: any;
  value: string;
  column: any;
}

export const GoToFilmCell: React.FC<IGoToFilmCell> = ({ row, value }) => {
  const selectedAdminTable = selectedTableSignal.value
  const url = getFilmUrl(row.original.platform_id, row.original, selectedAdminTable)
  return (
    <span className="link-cell"
      onClick={() =>
        window.open(
          url,
          "_blank"
        )
      }
    >
      {value}
    </span>
  );
};
