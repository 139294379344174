import { useCallback, useEffect, useState } from "react";
import {
  buttonCells,
  copyValueCells,
  dateCells,
  goToMovieCells,
  numberInputCells,
  onOffCells,
  platformCells,
  resolutionCells,
  textInputCells,
  toggleCells,
  yesNoCells,
} from "./useSharedTableData";
import { providerMap } from "../../app/app.types";
import { AdminTableSelection } from "../../features/adminFeatures/tables/tableTypes";
import { ToggleComponent } from "./ToggleComponent";
import { VersionInput } from "./VersionInput";
import { CheckCircleOutline, Close } from "@mui/icons-material";
import {
  ButtonCellComponenet,
  CellGroup,
  CopyCells,
  DateCell,
  NumberInputCell,
  PlatformYesNo,
  ScoreDropDown,
  ResolutionCell,
} from "./CustomCellComponents";
import { OnOffCell } from "./CustomCellComponents/OnOffCell";
import { SortTypeCell } from "./CustomCellComponents/SortType";
import { GoToFilmCell } from "./CustomCellComponents/GoToFilm";
import { updatePlatform } from "../../features/adminFeatures/tables/table.api";
import { selectedTableSignal } from "../utils";
import { TextField } from "@mui/material";

interface CustomCellProps {
  value: any;
  row: any;
  column: any;
  updateMyData: Function;
}

const CustomCell: React.FC<CustomCellProps> = ({
  value: initialValue,
  row,
  column,
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue!);
  const isGrouped = row.isGrouped;
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };
  // We'll only update the external data when the input is blurred
  const onInputBlur = useCallback(
    async (e: { target: { value: any } }) => {
      // eslint-disable-next-line eqeqeq
      if (e.target.value != initialValue) {
        const changeVaule = numberInputCells.includes(column.id)
          ? Number(value)
          : value;
        const res: any = await updatePlatform({
          platform_id: row.original.platform_id,
          [column.id]: changeVaule,
        });
        if (!res.error) {
          updateMyData(row.index, column.id, value, row);
        } else {
          setValue(initialValue);
        }
      }
    },
    [column.id, initialValue, row, updateMyData, value]
  );

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    if (column.id === "url") {
    }
    setValue(initialValue);
  }, [initialValue]);

  if (isGrouped) {
    return <CellGroup row={row} column={column} />;
  }
  if (column.id === "clearplay_score") {
    return (
      <ScoreDropDown
        value={value}
        setValue={setValue}
        row={row}
        updateMyData={updateMyData}
        column={column}
        initialValue={initialValue}
      />
    );
  } else if (column.id === "minimumVersion") {
    return (
      <VersionInput setValue={setValue} value={value} onBlur={onInputBlur} />
    );
  } else if (
    selectedTableSignal.value === AdminTableSelection.PLATFORM_CONTROLS &&
    toggleCells.includes(column.id)
  ) {
    return (
      <ToggleComponent
        initialValue={initialValue}
        column={column}
        row={row}
        setValue={setValue}
        updateMyData={updateMyData}
        value={value}
      />
    );
  } else if (column.id === "isActive") {
    return (
      <span>
        {value ? (
          <CheckCircleOutline htmlColor="green" />
        ) : (
          <Close color="error" />
        )}
      </span>
    );
  } else if (numberInputCells.includes(column.id)) {
    return (
      <NumberInputCell
        value={value}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      />
    );
  } else if (textInputCells.includes(column.id)) {
    return (
      <TextField
        className="cellInput"
        variant="standard"
        value={value ? value : ""}
        onChange={onInputChange}
        onBlur={onInputBlur}
        multiline
      />
    );
  } else if (buttonCells.includes(column.id)) {
    return <ButtonCellComponenet column={column} row={row} value={value} />;
  } else if (dateCells.includes(column.id)) {
    return <DateCell row={row} value={value} />;
  } else if (
    platformCells.includes(column.id) &&
    selectedTableSignal.value === AdminTableSelection.PLATFORM_CONTROLS
  ) {
    return <span>{providerMap[value]}</span>;
  } else if (
    selectedTableSignal.value !== AdminTableSelection.PLATFORM_CONTROLS &&
    yesNoCells.includes(column.id)
  ) {
    return <PlatformYesNo column={column} row={row} value={value} />;
  } else if (resolutionCells.includes(column.id)) {
    return (
      <ResolutionCell
        row={row}
        column={column}
        value={value}
        updateMyData={updateMyData}
      />
    );
  } else if (copyValueCells.includes(column.id)) {
    return <CopyCells row={row} column={column} value={value} />;
  } else if (onOffCells.includes(column.id)) {
    return <OnOffCell column={column} value={value} />;
  } else if (column.id === "asset_list_sort_id") {
    return <SortTypeCell value={value} />;
  } else if (goToMovieCells.includes(column.id)) {
    return <GoToFilmCell value={value} row={row} column={column} />;
  } else if (column.id === "url") {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        URL
      </a>
    );
  } else {
    return <span>{value}</span>;
  }
};

export default CustomCell;
