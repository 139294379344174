import React from "react";
import './CellComponents.scss'
import { Tooltip } from "@mui/material";
import { userMessageSignal } from "../../../features/user/userSignals";

interface TruncatedCellProps {
  value: string; // Define the type of value; update if it can be null/undefined
}

const TruncatedCell: React.FC<TruncatedCellProps> = ({ value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    userMessageSignal.value = { message: `Copied to clipboard: ${value}`, color: 'green' };
  };

  return (
    <Tooltip className="toolTipCell" title={value} placement="top" arrow>
    <div id="truncatedCell"
      onClick={handleCopy}
    >
      {value?.length > 10 ? `${value.slice(0, 10)}...` : value}
    </div>
    </Tooltip>
  );
};

export default TruncatedCell;